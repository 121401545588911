<template>
  <div class="layout-boxed">
    <div class="page-content">
      <div class="content-wrapper">
        <div class="content-inner">
          <div class="content">
            <div class="card">
              <div class="card-header">
                <a v-if="page == 'start'" href="https://rufso.ru/" target="_blank">
                  <img :src="id == 1 ? '/images/logo_rufso.png' : '/images/logo_default.png'" style="float: right; max-width: 150px;" />
                </a>
                <h2 class="card-title">{{ quiz ? quiz.name : '' }}</h2>
                <span class="text-muted d-block" v-if="page == 'quiz'" :key="current_ts"
                  >вопрос: {{ result.question_index }}/{{ scenario.count_questions }}, время:
                  {{ secondsToTime(result.seconds) }}/{{
                    secondsToTime(scenario.timer_seconds)
                  }}</span
                >
              </div>
              <div class="card-body" v-if="quiz && page == 'start'">
                <div class="d-flex justify-content-between align-items-center mb-2" v-for="scenario in quiz.scenarios" :key="scenario.id">
                    <div><strong>{{scenario.name}}</strong></div>
                    <div>Количество вопросов: <strong>{{scenario.count_questions}}</strong></div>
                    <div>Верно ответить на: <strong>{{scenario.true_answers}}</strong></div>
                    <div>Время: <strong>{{ secondsToTime(scenario.timer_seconds) }}</strong></div>
                  <div
                    class="btn btn-primary ml-3"
                    @click="run(scenario.id)"
                  >
                    Начать
                  </div>
                </div>
              </div>
              <div v-if="page == 'quiz'">
                <div class="progress rounded-0">
                  <div
                    v-for="index in scenario.count_questions"
                    :key="index"
                    :class="
                      index < result.question_index
                        ? 'progress-bar bg-primary progress-quiz'
                        : index === result.question_index
                        ? 'progress-bar bg-info progress-bar-striped progress-bar-animated progress-quiz'
                        : 'progress-bar bg-dark-100 progress-quiz'
                    "
                  >
                    <span>{{ index }}</span>
                  </div>
                </div>
                <div class="progress rounded-0" style="height: 0.175rem">
                  <div
                    class="progress-ba"
                    :class="'bg-' + colorTimer()"
                    :style="
                      'width: ' + (result.seconds / scenario.timer_seconds) * 100 + '%'
                    "
                  >
                    <span class="sr-only" :key="current_ts"
                      >{{ result.seconds }}/{{ scenario.timer_seconds }}</span
                    >
                  </div>
                </div>
                <ul class="media-list media-list-linked media-list-bordered" style="font-size: 1.1em;">
                  <li class="media bg-light font-weight-semibold py-2" :key="question.question">
                    {{ question.question }}
                  </li>
                  <li
                    v-for="(option, index) in question.options"
                    :key="index"
                    class="li"
                    @click="!submit ? selectOption(option.id) : false"
                    :class="
                      option.id == quiz_option_id_selected ? 'bg-primary-100' : ''
                    "
                  >
                    <a href="#" class="media">
                      <div class="my-auto mr-3">
                        <div
                          class="far fa-circle"
                          v-if="option.id != quiz_option_id_selected"
                        ></div>
                        <div
                          class="fas fa-check-circle text-primary"
                          v-if="option.id == quiz_option_id_selected"
                        ></div>
                      </div>

                      <div class="media-body" :key="option.option">
                        {{ option.option }}
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="card-footer">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span
                      ><span v-if="submit"
                        ><i class="icon-spinner2 spinner mr-2"></i>
                        {{ submit }}</span
                      ></span
                    >
                    <button
                      type="submit"
                      class="btn btn-primary ml-3"
                      @click="answer()"
                      :disabled="!quiz_option_id_selected"
                    >
                      Далее
                    </button>
                  </div>
                </div>
              </div>
              <div class="card-body" v-if="page == 'score'">
                <div class="row">
                  <div class="col-sm-6">
                    <div
                      class="card card-body text-white has-bg-image"
                      :class="
                        result.score >= scenario.true_answers
                          ? 'bg-success'
                          : 'bg-danger'
                      "
                    >
                      <div class="media">
                        <div class="mr-3 align-self-center">
                          <i class="icon-checkmark2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                          <h3 class="mb-0">
                            {{ result.score }} из {{ scenario.count_questions }}
                          </h3>
                          <span class="text-uppercase font-size-xs"
                            >правильных ответов</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-6">
                    <div
                      class="card card-body text-white has-bg-image"
                      :class="
                        result.seconds > scenario.timer_seconds
                          ? 'bg-danger'
                          : 'bg-success'
                      "
                    >
                      <div class="media">
                        <div class="mr-3 align-self-center">
                          <i class="icon-watch2 icon-3x opacity-75"></i>
                        </div>

                        <div class="media-body text-right">
                          <h3 class="mb-0">
                            {{ secondsToTime(result.seconds) }} из
                            {{ secondsToTime(scenario.timer_seconds) }}
                          </h3>
                          <span class="text-uppercase font-size-xs"
                            >затраченное время</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul
                class="media-list media-list-bordered"
                v-if="page == 'score' && errors.length > 0"
              >
                <li class="media bg-light font-weight-semibold py-2">
                  Неправильные ответы
                </li>
                <li v-for="error in errors" :key="error.question" class="media">
                  <div class="media-body">
                    <span class="media-title2 d-block font-weight-semibold">
                      <i class="icon-question4 mr-1" title="Вопрос"></i>
                      {{ error.question }}</span
                    >
                    <div class="text-danger mt-1">
                      <i
                        class="icon-cancel-square mr-1"
                        title="Неверный ответ"
                      ></i>
                      {{ error.answer }}
                    </div>
                    <div class="text-muted mt-1">
                      <i
                        class="icon-bubble-notification mr-1"
                        title="Подсказка для ответа"
                      ></i>
                      {{ error.hint }}
                    </div>
                  </div>
                </li>
              </ul>
                    <button
                      type="submit"
                      class="btn btn-primary mb-3 mx-3"
                      @click="reset()"
                        v-if="page == 'score'"
                    >
                      Вернуть в начало
                    </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
        quiz_option_id_selected: 0,
        current_ts: 0,
        scenario_id: null,
        page: "start",
      submit: false,
      timer: null,
      errors: [],
    };
  },
  computed: {
    id: {
      get() {
        return this.$route.params.id;
      },
    },
    quiz: {
      get() {
        return this.$store.state.quiz;
      },
    },
    result: {
      get() {
        return this.$store.getters['getResult'];
      },
    },
    scenario: {
      get() {
        return this.$store.getters['quizScenario'](this.result.quiz_scenario_id);
      },
    },
    questions: {
      get() {
        return this.$store.state.questions;
      },
    },
    question: {
      get() {
        return this.$store.state.question;
      },
    },
  },
  mounted() {
    this.$store.dispatch("GET_QUIZ", this.id).then(() => {
        document.title = this.quiz.name;
    });
  },
  methods: {
    run(quiz_scenario_id) {
        let inside = this;
        inside.submit = 'Отправка...';
        this.$store.dispatch("START_QUIZ", quiz_scenario_id).then(() => {
            this.startTimer();
            inside.page = "quiz";
        inside.submit = false;
        })
    },
    answer() {
        if (!this.quiz_option_id_selected) {
            return;
        }
        let inside = this;
        inside.submit = 'Отправка...';
        this.$store.dispatch("SEND_ANSWER", this.quiz_option_id_selected).then((response) => {
            this.quiz_option_id_selected = 0;
            inside.page = inside.result.question_index > inside.scenario.count_questions ? "score" : "quiz";
            if (inside.page == "score") {
                inside.errors = response.result.errors;
                inside.stopTimer();
            }
            inside.submit = false;
        });
    },
    secondsToTime(seconds) {
      return moment.unix(seconds).utc().format("H:mm:ss");
    },
    colorTimer() {
      let question = this.result.question_index / this.scenario.count_questions;
      let question_last = (this.result.question_index - 1) / this.scenario.count_questions;
      let seconds = this.result.seconds / this.scenario.timer_seconds;
      return seconds < question_last
        ? "success"
        : seconds < question
        ? "yellow"
        : "danger";
    },
    startTimer() {
        this.current_ts = moment().unix() + this.result.diff_ts;
      this.timer = setInterval(() => {
        this.current_ts = moment().unix() + this.result.diff_ts;
        this.$store.dispatch("REFRESH_SECONDS", this.current_ts - this.result.started_ts);
      }, 1000);
    },
    stopTimer() {
      clearTimeout(this.timer);
    },
    selectOption(id) {
      this.quiz_option_id_selected = id;
    },
    reset() {
        this.page = "start";
    },
  },
};
</script>